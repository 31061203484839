<template>
  <v-container fluid class="mb-10">
    <div class="autho">
      <input v-model="param.startDate" type="date" style="width:12%" class="blue_input01" max="9999-12-31">~<input v-model="param.endDate" type="date" style="width:12%" class="blue_input01" max="9999-12-31">

      <v-btn
        dark
        tile
        color="#6999f1"
        class="font-weight-bold"
        width="100px"
        @click="search"
      >
        검색
      </v-btn>
    </div>
    <!--.autho-->


    <div class="adminCont">

      <fieldset>
        <legend>기간별 기술이전 실적</legend>

        <div class="admintable clear">
          <div class="adminTa01">
            <span class="caption1">기간별 기술이전 실적</span>
            <table  cellspacing="0" cellpadding="1px" width="100%">
              <colgroup>
                <col width="24%">
                <col width="18%">
                <col width="20%">
                <col width="18%">
                <col width="20%">
              </colgroup>
              <thead>
              <tr>
                <td class="tit" rowspan="2">구분</td>
                <td class="tit" colspan="2">{{ dateHeader.prevStartDate }} ~ {{ dateHeader.prevEndDate }}</td>
                <td class="tit" colspan="2">{{ dateHeader.startDate }} ~ {{ dateHeader.endDate }}</td>
              </tr>
              <tr>
                <td class="tit">건수</td>
                <td class="tit">기술료(천원)</td>
                <td class="tit">건수</td>
                <td class="tit">기술료(천원)</td>
              </tr>
              </thead>
              <tbody v-if="techTypeResultStat.length > 0">
              <tr v-for="(stat, index) in techTypeResultStat" :key="index">
                <td class="td_blueA">{{ stat.techType }}</td>
                <td class="td_blueA">{{ stat.prevTechCount }}</td>
                <td class="td_blueA">{{ moneyFormat(stat.prevTechFixAmount) }}</td>
                <td class="td_blueA">{{ stat.techCount }}</td>
                <td class="td_blueA">{{ moneyFormat(stat.techFixAmount) }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="5" class="td_blueA text-center">데이터를 찾을 수 없습니다.</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="adminTa02">
            <span class="caption1">연도별 기술이전 실적</span>
            <table cellspacing="0" cellpadding="1px" width="100%">

              <colgroup>
                <col width="25%">
                <col width="25%">
                <col width="25%">
                <col width="25%">
              </colgroup>
              <thead>
              <tr>
                <td class="tit">기준연도</td>
                <td class="tit">건수</td>
                <td class="tit">기술료(천원)</td>
                <td class="tit">건당 기술료(천원)</td>
              </tr>
              </thead>
              <tbody v-if="techFeeStat.length > 0">
              <tr v-for="(techFee, index) in techFeeStat" :key="index">
                <td class="td_blueA">{{ techFee.baseYear }}</td>
                <td class="td_blueA">{{ moneyFormat(techFee.contractCount) }}</td>
                <td class="td_blueA">{{ moneyFormat(techFee.supplyAmount) }}</td>
                <td class="td_blueA">{{ moneyFormat(techFee.averageSupplyAmount) }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td class="td_blueA" colspan="4">데이터를 찾을 수 없습니다.</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div><!--.admintable-->
        <div class="admintableB clear">
          <div class="adminTa03">
            <span class="caption1">기술이전 계약체결 건수와 계약에 포함된 기술건수</span>
            <table  cellspacing="0" cellpadding="1px" width="100%">

              <colgroup>
                <col width="20%">
                <col width="14%">
                <col width="10%">
                <col width="8%">
                <col width="8%">
                <col width="8%">
                <col width="8%">
                <col width="8%">
                <col width="8%">
                <col width="8%">
              </colgroup>
              <thead>
              <tr>
                <th class="tit" rowspan="3">구분</th>
                <th class="tit" rowspan="3">계약 체결 건수</th>
                <th class="tit" colspan="8">지식재산권 등 이전기술수</th>
              </tr>
              <tr>
                <th class="tit" rowspan="2">총합</th>
                <th class="tit" colspan="7">기술 분야</th>
              </tr>
              <tr>
                <th class="tit">IT</th>
                <th class="tit">BT</th>
                <th class="tit">NT</th>
                <th class="tit">ST</th>
                <th class="tit">ET</th>
                <th class="tit">CT</th>
                <th class="tit">기타</th>
              </tr>
              </thead>
              <tbody v-if="techFieldStat.length > 0">
              <tr v-for="(stat, index) in techFieldStat" :key="index">
                <td class="td_blueA">{{ stat.transactionType }}</td>
                <td class="td_blueA">{{ stat.totalCount }}</td>
                <td class="td_blueA">{{ stat.totalSum }}</td>
                <td class="td_blueA">{{ stat.itSum }}</td>
                <td class="td_blueA">{{ stat.btSum }}</td>
                <td class="td_blueA">{{ stat.ntSum }}</td>
                <td class="td_blueA">{{ stat.stSum }}</td>
                <td class="td_blueA">{{ stat.etSum }}</td>
                <td class="td_blueA">{{ stat.ctSum }}</td>
                <td class="td_blueA">{{ stat.etcSum }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td class="td_blueA" colspan="10">데이터를 찾을 수 없습니다.</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="adminTa04">
            <span class="caption1">계약 및 기술유형별 이전된 기술건수</span>
            <table  cellspacing="0" cellpadding="1px" width="100%">

              <colgroup>
                <col width="12%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
                <col width="9%">
              </colgroup>
              <thead>
              <tr>
                <th class="tit" rowspan="2">구분</th>
                <th class="tit" colspan="10">지식재산권 등 이전기술수</th>
              </tr>
              <tr>
                <th class="tit">노하우</th>
                <th class="tit">특허</th>
                <th class="tit">산업자문</th>
                <th class="tit">실용신안</th>
                <th class="tit">디자인/의장</th>
                <th class="tit">상표</th>
                <th class="tit">소프트웨어</th>
                <th class="tit">저작권</th>
                <th class="tit">MTA</th>
                <th class="tit">기타</th>
              </tr>
              </thead>
              <tbody v-if="techTypeStat.length > 0">
              <tr v-for="(stat, index) in techTypeStat" :key="index">
                <td class="td_blueA">{{ stat.transactionType }}</td>
                <td class="td_blueA">{{ stat.sum1 }}</td>
                <td class="td_blueA">{{ stat.sum2 }}</td>
                <td class="td_blueA">{{ stat.sum3 }}</td>
                <td class="td_blueA">{{ stat.sum4 }}</td>
                <td class="td_blueA">{{ stat.sum5 }}</td>
                <td class="td_blueA">{{ stat.sum6 }}</td>
                <td class="td_blueA">{{ stat.sum7 }}</td>
                <td class="td_blueA">{{ stat.sum8 }}</td>
                <td class="td_blueA">{{ stat.sum9 }}</td>
                <td class="td_blueA">{{ stat.sumZ }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td class="td_blueA" colspan="11">데이터를 찾을 수 없습니다.</td>
              </tr>
              </tbody>
            </table>
          </div>


        </div><!--.admintable-->
      </fieldset>
    </div>
    <!--codeCont-->
    <!--업체정보 입력-->

    <loading-dialog :loading="loading"/>
  </v-container>
</template>

<script>
import moment from 'moment'
import LoadingDialog from '@/views/dialog/LoadingDialog'

export default {
  name: 'ContractTechStat',
  components: {LoadingDialog},
  data() {
    return {
      loading: false,
      param: {
        startDate: null,
        endDate: null
      },
      dateHeader: {
        prevStartDate: null,
        prevEndDate: null,
        startDate: null,
        endDate: null
      },
      techTypeResultStat: [],
      depositStat: [],
      techFieldStat: [],
      techTypeStat: [],
      techFeeStat: [],
    }
  },
  mounted() {
    const now = moment()
    this.param.endDate = now.subtract(1, 'day').format('YYYY-MM-DD')
    this.param.startDate = now.startOf('year').format('YYYY-MM-DD')

    this.search()
  },
  methods: {
    search() {
      this.setDateHeaderText()
      this.loading = true
      return this.searchTechTypeResultStat()
          .then(this.searchTechFeeStat)
          .then(this.searchTechFieldStat)
          .then(this.searchTechTypeStat)
          .finally(() => this.loading = false)
    },
    searchTechTypeResultStat() {
      return this.$http.get('/api/contract/stat/techTypeResult', {params: this.param})
      .then(({ data }) => {
        this.techTypeResultStat = data
      })
    },
    searchTechFieldStat() {
      return this.$http.get('/api/contract/stat/techField', {params: this.param})
      .then(({ data }) => {
        this.techFieldStat = data
      })
    },
    searchTechTypeStat() {
      return this.$http.get('/api/contract/stat/techType', {params: this.param})
      .then(({ data }) => {
        this.techTypeStat = data
      })
    },
    searchTechFeeStat() {
      return this.$http.get('/api/contract/stat/techFee', {params: this.param})
      .then(({ data }) => {
        this.techFeeStat = data
      })
    },
    setDateHeaderText() {
      this.dateHeader.prevStartDate = moment(this.param.startDate, 'YYYY-MM-DD').subtract(1, 'year').format('YYYY.MM.DD.')
      this.dateHeader.prevEndDate = moment(this.param.endDate, 'YYYY-MM-DD').subtract(1, 'year').format('YYYY.MM.DD.')
      this.dateHeader.startDate = moment(this.param.startDate, 'YYYY-MM-DD').format('YYYY.MM.DD.')
      this.dateHeader.endDate = moment(this.param.endDate, 'YYYY-MM-DD').format('YYYY.MM.DD.')
    }
  }
}
</script>
